import {
	AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as FontFaceObserver from 'font-face-observer';
import { interval, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { ApplicationState } from '../../store/application-state';
import { BreadcrumbsQuery } from '../../store/reducers/breadcrumbs.reducer';
import { Breadcrumb } from './models/breadcrumb';

/**
 * @group Generic Compounds
 * @component Breadcrumb
 */
@Component({
	selector: 'bas-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'[class.scrollable]': 'scrollable',
		'[class.transition]': 'transition',
	},
})
export class BreadcrumbComponent implements OnInit, AfterViewInit {

	private static Timer = 100;

	breadcrumbs = this.store.selectSignal(BreadcrumbsQuery.getBreadcrumbs);
	scroll$: Subject<'left' | 'right' | 'none'> = new Subject();
	margin$: Observable<number>;

	scrollable = false;
	transition = false;

	@ViewChild('items', { static: false })
	items: ElementRef;

	@ViewChild('itemContainer', { static: false })
	itemContainer: ElementRef<HTMLDivElement>;

	margin = 0;
	maxMargin: number;
	minMargin = 0;

	private lastChecked: number = null;

	constructor(
		@Inject(Store) private store: Store<ApplicationState>,
		private router: Router,
		private cdr: ChangeDetectorRef,
	) {
	}

	ngOnInit() {
		this.margin$ = this.scroll$.pipe(
			switchMap(val => interval(BreadcrumbComponent.Timer)
				.pipe(map(() => val))),
			map(step => this.getNextMargin(step)),
			filter(margin => margin !== this.margin),
		);
	}

	trackBreadCrumb(breadcrumb: Breadcrumb): string {
		return breadcrumb.url;
	}

	scroll(to: 'left' | 'right' | 'none') {
		if (to !== 'none') {
			this.transition = true;
			this.cdr.markForCheck();
			this.margin$.pipe(
				takeUntil(this.scroll$.pipe(
					filter(val => val === 'none'),
					debounceTime(BreadcrumbComponent.Timer),
				)),
			)
				.subscribe({
					next: val => {
						this.margin = val;
						this.cdr.markForCheck();
					},
					complete: () => {
						this.transition = false;
						this.cdr.markForCheck();
					},
				});
		}
		this.scroll$.next(to);
	}

	isStrutsLink(url: string) {
		return url.indexOf('.do') > 0;
	}

	navigateRouted(breadcrumb: Breadcrumb, event: MouseEvent) {
		this.router.navigateByUrl(this.getExtendedUrl(breadcrumb), /* Removed unsupported properties by Angular migration: queryParams. */ {});
		event.preventDefault();
	}

	getExtendedUrl(breadcrumbrl: Breadcrumb) {
		if (this.isStrutsLink(breadcrumbrl.url)) {
			return '/jump.do?id=' + breadcrumbrl.id;
		}
		return breadcrumbrl.url;
	}

	ngAfterViewInit(): void {
		const itemWidth = this.items.nativeElement.offsetWidth;
		if (itemWidth === 0 || itemWidth === this.lastChecked) {
			return;
		}
		const observer = new FontFaceObserver('Audi Type Extended Normal', {
			weight: 400,
		});
		observer.check()
			.then(() => this.checkOverflow(), () => {
			});
	}

	private checkOverflow() {
		const itemWidth = this.items.nativeElement.scrollWidth;
		this.lastChecked = itemWidth;
		const containerWidth = this.itemContainer.nativeElement.offsetWidth;

		const scrollable = itemWidth >= containerWidth;
		const hasChanged = this.scrollable !== scrollable;
		if (!hasChanged) {
			return;
		}
		this.scrollable = scrollable;
		this.maxMargin = (itemWidth - containerWidth + 90) * -1;
		this.margin = this.maxMargin;
		this.cdr.markForCheck();
	}

	private checkMinMaxMargin(margin: number): number {
		if (margin >= 0) {
			return 0;
		}
		if (margin < this.maxMargin) {
			return this.maxMargin;
		}

		return margin;
	}

	private getNextMargin(step: 'none' | 'left' | 'right'): number {
		const stepSize = 25;
		if (step === 'left') {
			return this.checkMinMaxMargin(this.margin + stepSize);
		}
		if (step === 'right') {
			return this.checkMinMaxMargin(this.margin - stepSize);
		}

		return this.margin;
	}
}

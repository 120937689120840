import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../../shared/store/application-state';
import { NavigationItem } from '../../../models/navigationItem';
import { NavigationQuery } from '../../../store/navigation.reducer';

@Component({
	selector: 'bas-side-nav-hotlink',
	templateUrl: './side-nav-hotlink.component.html',
	styleUrls: ['./side-nav-hotlink.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'[class.topLevel]': 'topLevel()',
	},
})
export class SideNavHotlinkComponent {

	items = input<Array<NavigationItem>>();
	topLevel = input(false);

	activeItemId = this.store.selectSignal(NavigationQuery.getActiveId);

	constructor(private store: Store<ApplicationState>) {
	}

	showChildren(item: NavigationItem | null): Signal<boolean> {
		return computed(() => this.activeItemId() === item.id || this.hasIdRecursive(item.children, this.activeItemId()));
	}

	private hasIdRecursive(items: Array<NavigationItem>, id: string): boolean {
		if (!items || items.length === 0) {
			return false;
		}
		const inList = items.some(item => item.id === id);
		if (inList) {
			return true;
		}
		const children = items.flatMap(item => (item.children?.length && item.children) || []);

		return this.hasIdRecursive(children, id);
	}

	isItemHeadline(item: NavigationItem): boolean {
		return item.parameters['headline'];
	}
}
